import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationSettings } from "@/interfaces/organization/settings/OrganizationSettings";
import type { Organization } from "@/interfaces/organization/Organization";
import axios from "axios";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import { handleApiError } from "@/api/generic/ApiErrorHandler";

class OrganizationSettingsApi extends OrganizationGenericEntityApi<OrganizationSettings> {
  apiIdentifier = "organization_settings";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/settings`;
  }

  parseEntity(entityFromApi: any) {
    if (entityFromApi.settings?.data) {
      entityFromApi.settings = entityFromApi.settings.data.map((data: any) => {
        return useOrganizationSettingsApi().parseEntity(data);
      });
    }

    return new ApiResponseParser(
      entityFromApi,
      "organization_settings",
    ).build() as OrganizationSettings;
  }

  async getOrganizationSettings(args: { organization: Organization }) {
    try {
      const { data: response } = await axios.get(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}`,
        { headers: authHeader() },
      );

      return response.data as OrganizationSettings;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getSettings(args: { organization: Organization }) {
    try {
      const { data: response } = await axios.get(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/list-definitions`,
        { headers: authHeader() },
      );

      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async updateSettings(args: {
    organization: Organization;
    key: string;
    value: string;
  }) {
    try {
      const { data: response } = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/${args.key}`,
        {
          value: args.value,
        },
        {
          headers: authHeader(),
        },
      );

      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationSettingsApi() {
  return new OrganizationSettingsApi();
}
